import * as React from 'react';
import * as style from './WishlistApp.scss';
import {EmptyState} from '../EmptyState/EmptyState';
import {IProduct, LoadMoreType} from '@wix/wixstores-client-gallery/dist/es/src/types/galleryTypes';
import {IPropsInjectedByViewerScript} from '../../types/app-types';
import {ISantaProps} from '@wix/native-components-infra/dist/src/types/types';
import {MINIMAL_AMOUNT_OF_PRODUCT_PLACHOLDERS} from '../../commons/getDefaultStyleParams';
import {Pagination} from './Pagination/Pagination';
import {ProductListGrid} from '@wix/wixstores-client-gallery/dist/es/src/gallery/components/ProductList/ProductListGrid';
import {WishlistProductItem} from '../WishlistProductItem/WishlistProducItem';
import {withGlobalProps} from '../../providers/globalPropsProvider';
import {LoadMore} from './LoadMore/LoadMore';

const inlineCss = require('!raw-loader!../../styles/inlineStyle.css');

export interface IWishlistProps extends ISantaProps {
  globals: IPropsInjectedByViewerScript;
}

@withGlobalProps
export class WishlistApp extends React.Component<IWishlistProps> {
  constructor(props) {
    super(props);
    this.reportAppLoaded = this.reportAppLoaded.bind(this);
  }

  public componentDidMount() {
    this.props.host.registerToComponentDidLayout(this.reportAppLoaded);
  }

  private renderGrid() {
    const {isMobile} = this.props.globals;
    const nextProducts = this.props.globals.products.reduce((acc, product, index) => {
      acc[index] = product;
      return acc;
    }, Array.from({length: MINIMAL_AMOUNT_OF_PRODUCT_PLACHOLDERS}));

    return (
      <ProductListGrid
        gridType={this.props.globals.gridType}
        products={nextProducts as IProduct[]}
        ProductItem={WishlistProductItem}
        isMobile={isMobile}
      />
    );
  }

  private renderHeader() {
    const {
      textsMap: {wishilistHeaderTitle, wishlistHeaderSubtitle},
    } = this.props.globals;
    return (
      <header className={style.header}>
        <h1 data-hook="title" className={style.title}>
          {wishilistHeaderTitle}
        </h1>
        <p data-hook="subtitle" className={style.subtitle}>
          {wishlistHeaderSubtitle}
        </p>
      </header>
    );
  }

  private renderEmptyState() {
    const {
      isMobile,
      textsMap: {emptyStateText, emptyStateLinkText},
      handleClickOnEmptyState,
      homePageUrl,
    } = this.props.globals;

    return (
      <EmptyState
        isMobile={isMobile}
        texts={{
          noProductsMessage: emptyStateText,
          link: emptyStateLinkText,
        }}
        dataHook={'empty-state'}
        handleClick={handleClickOnEmptyState}
        linkUrl={homePageUrl}
      />
    );
  }

  private renderProducts() {
    const {loadMoreType, hasMoreProducts} = this.props.globals;
    const Paginate = loadMoreType === LoadMoreType.PAGINATION ? Pagination : LoadMore;
    const shouldPagiate = loadMoreType === LoadMoreType.PAGINATION || hasMoreProducts === true;

    return (
      <>
        {this.renderGrid()}
        {shouldPagiate && <Paginate />}
      </>
    );
  }

  public render() {
    const {isLoaded, signature, isEmptyState} = this.props.globals;
    /* istanbul ignore next: hard to test it */
    if (!isLoaded) {
      return null;
    }

    return (
      <main className={style.root} data-hook="app-wrapper">
        {this.renderHeader()}
        <style dangerouslySetInnerHTML={{__html: inlineCss}} />
        <div data-hook="wishlist-content" data-signature={signature} className={style.content}>
          {isEmptyState ? this.renderProducts() : this.renderEmptyState()}
        </div>
      </main>
    );
  }

  private reportAppLoaded() {
    /* istanbul ignore next: hard to test it */
    this.props.globals.onAppLoaded && this.props.globals.onAppLoaded();
  }
}
